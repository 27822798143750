
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
    width="900px"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'100px'"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名" prop="name">
              <el-input
                v-model="formModel.name"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="formModel.phone"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>        
        <el-row>
          <el-col :span="12">
            <el-form-item label="单位" prop="companyId">
              <el-select-tree
                :props="props"
                :options="companyResult"
                v-model="formModel.companyId"
                height="200"
                style="width:330px;"
                size="large"
              ></el-select-tree>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户身份" prop="personRoleId">
              <el-select
                filterable
                placeholder="请选择"
                style="width: 280px"
                v-model="formModel.personRoleId"
              >
                <el-option
                  v-for="role in personRoleResult"
                  :key="role.personRoleName"
                  :label="role.personRoleName"
                  :value="role.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="身份证" prop="idCard">
              <el-input
                v-model="formModel.idCard"
                placeholder="请输入身份证"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="关联单位" prop="personCompany">
              <el-select
                v-model="formModel.personCompany"
                filterable
                multiple
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="company in companyResult"
                  :key="company.id"
                  :label="company.name"
                  :value="company.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="照片" prop="faceImageUrl">
              <el-upload
                class="avatar-uploader"
                name="photoFile"
                :action="uploadUrl"
                :show-file-list="false"
                :headers="headers"
                :data="uploadData"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="fileUrl" :src="fileUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="开放权限">
              <el-checkbox v-model="formModel.faceEnabled"
                >人脸识别</el-checkbox
              >
              <el-checkbox v-model="formModel.cardEnabled">门禁卡</el-checkbox>
              <el-checkbox v-model="formModel.appEnabled">手机开锁</el-checkbox>
              <el-checkbox v-model="formModel.guestEnabled"
                >访客权限</el-checkbox
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="同步人脸信息" prop="faceBound">
              <el-radio-group v-model="formModel.faceBound">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="微信通知" prop="wechatNoticeEnabled">
              <el-radio-group v-model="formModel.wechatNoticeEnabled">
                <el-radio :label="true">接受</el-radio>
                <el-radio :label="false">拒绝</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="是否允许查看本单位数据" prop="allowViewLocal">
              <el-radio-group v-model="formModel.allowViewLocal">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row>
          <el-col :span="12">
            <el-form-item :label="companyPosition1" prop="position1">
              <el-input
                v-model="formModel.position1"
                placeholder="请输入一级位置"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="companyPosition2" prop="position2">
              <el-input
                v-model="formModel.position2"
                placeholder="请输入二级位置"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="companyPosition3" prop="position3">
              <el-input
                v-model="formModel.position3"
                placeholder="请输入三级位置"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="companyPosition4" prop="position4">
              <el-input
                v-model="formModel.position4"
                placeholder="请输入四级位置"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="companyPosition5" prop="position5">
              <el-input
                v-model="formModel.position5"
                placeholder="请输入五级位置"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import personInfoApi from "@/api/base/personInfo";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import personCompanyApi from "@/api/base/personCompany";
import companyInfoApi from "@/api/base/companyInfo";
import personRoleInfoApi from "@/api/base/personRoleInfo";
import SelectTree from "@/components/SelectTree";

import { getToken } from "@/utils/auth"; // get token from cookie

export default {
  props: [
    "businessKey",
    "title",
    "companyResult",
    "companyPosition1",
    "companyPosition2",
    "companyPosition3",
    "companyPosition4",
    "companyPosition5",
  ],
  watch: {
    "formModel.companyId":function(newVal,oldVal){
        var self = this;

        if(newVal!=null){
          var formData = new FormData();
          formData.append("companyId", newVal);

          //查询单位下角色
          personRoleInfoApi.listByCompanyId(formData)
          .then(response=>{
              var jsonData = response.data;

              if (jsonData.result) {
                self.personRoleResult = jsonData.data;
              }
          })
        }
    }
  },
  data() {
    let checkIdCard = (rule, value, callback) => {
      var identNumber = value;
      if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(identNumber)) {
        return callback(new Error("格式错误"));
      } else if (!/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(identNumber)) {
        //身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X。
        return callback(new Error("格式错误"));
      } else {
        return callback();
      }
    };
    return {
      formModel: {},
      ruleValidate: {
        companyId: [{ required: true, message: "请选择单位", trigger: "blur" }],
        name: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
        phone: [{ required: true, message: "手机号不能为空", trigger: "blur" }],
        personRoleId: [{ required: true, message: "请选择身份", trigger: "blur" }]
        // idCard: [
        //   { required: false, message: "身份证不能为空", trigger: "blur" },
        //   {
        //     validator: checkIdCard,
        //     trigger: "blur"
        //   }
        // ]
      },
      showDialog: true,
      loading: false,
      submitting: false,
      checkList: [],
      //上传地址
      uploadUrl: Constant.serverUrl + "/uploadPicture",
      uploadData: {
        subFolder: "personInfo",
      },
      fileUrl: "",
      headers: {
        Authorization: getToken(),
      },
      personRoleResult: [],
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
      parkList: [],
    };
  },
  created() {
    var self = this;

    companyInfoApi.list({ type: "3" }).then((response) => {
      var jsonData = response.data;
      this.parkList = jsonData.data;
    });

    this.loadTree();
  },
  methods: {
    getSelectedValue(value) {
      this.formModel.companyId = value;
    },
    getSelectedParkValue(value) {
      this.formModel.parkId = value;
    },
    loadTree() {
      var formData = new FormData();
      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;
            //数组转换为字符串
            //用户身份
            // if (
            //   self.formModel.popedom != null &&
            //   self.formModel.popedom != ""
            // ) {
            //   var popedom = self.formModel.popedom.join(",");
            //   self.formModel.popedom = popedom;
            // } else {
            //   self.formModel.popedom = "";
            // }

            //子单位
            if (
              self.formModel.personCompany != null &&
              self.formModel.personCompany != ""
            ) {
              var personCompany = self.formModel.personCompany.join(",");
              self.formModel.personCompany = personCompany;
            } else {
              self.formModel.personCompany = "";
            }

            if (self.formModel.companyId == null) {
              self.formModel.companyId = "";
            }

            if (id == null || id.length == 0) {
              return personInfoApi.add(self.formModel);
            } else {
              return personInfoApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
    handleAvatarSuccess(res, file) {
      var self = this;
      self.formModel.faceImageUrl = res.data;
      self.fileUrl =
        res.data + "?x-oss-process=image/resize,m_lfit,h_400,w_400";
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
  mounted: function () {
    var self = this;
    (function () {
      if (self.businessKey.length == 0) {
        return personInfoApi.create();
      } else {
        return personInfoApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
          let faceImageUrl = self.formModel.faceImageUrl;
          if (faceImageUrl != null) {
            self.fileUrl =
              faceImageUrl + "?x-oss-process=image/resize,m_lfit,h_400,w_400";
          }

          if (self.businessKey.length == 0) {
            //self.formModel.popedom = ["1"];
          } else {
            // var popedom = self.formModel.popedom;
            // if (popedom != "") {
            //   self.formModel.popedom = popedom.split(",");
            // }

            var personCompany = self.formModel.personCompany;
            if (personCompany != "") {
              self.formModel.personCompany = personCompany.split(",");
            }

            var wechatNoticeEnabled = self.formModel.wechatNoticeEnabled;
            if (wechatNoticeEnabled != "") {
              self.formModel.wechatNoticeEnabled = true;
            }
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
  components: {
    "el-select-tree": SelectTree,
  },
};
</script>