import request from '@/utils/request'
import constant from '@/constant'
import { getToken } from "@/utils/auth"; // get token from cookie

function pageList(formData) {
  return request.post(constant.serverUrl + "/base/oldPersonInfo/pageList", formData);
}

function create() {
  return request.get(constant.serverUrl + "/base/oldPersonInfo/create");
}

function edit(id) {
  return request.get(constant.serverUrl + "/base/oldPersonInfo/edit/" + id);
}

function add(formModel) {
  return request.post(constant.serverUrl + "/base/oldPersonInfo/add", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel) {
  return request.post(constant.serverUrl + "/base/oldPersonInfo/update", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id) {
  return request.post(constant.serverUrl + "/base/oldPersonInfo/delete/" + id);
}

function batchRemove(idList) {
  return request.post(constant.serverUrl + "/base/oldPersonInfo/batchDelete", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function exportXls(formData) {
  //导出xls
  return request.post(constant.serverUrl + "/base/oldPersonInfo/exportXls", formData);
  //window.open(constant.serverUrl + "/base/oldPersonInfo/exportXls?token=" + getToken());
}

function enabledFace(formData) {
  //人脸授权
  return request.post(constant.serverUrl + "/base/oldPersonInfo/enabledFace", formData);
}

function enabledFaceList(ids) {
  //批量人脸授权
  return request.post(constant.serverUrl + "/base/oldPersonInfo/enabledFaceList", ids, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function enabledCard(formData) {
  //刷卡授权
  return request.post(constant.serverUrl + "/base/oldPersonInfo/enabledCard", formData);
}

function enabledApp(formData) {
  //手机授权
  return request.post(constant.serverUrl + "/base/oldPersonInfo/enabledApp", formData);
}

function enabledWechatNotice(formData) {
  //接收微信通知
  return request.post(constant.serverUrl + "/base/oldPersonInfo/enabledWechatNotice", formData);
}

function enabledGuest(formData) {
  //访客授权
  return request.post(constant.serverUrl + "/base/oldPersonInfo/enabledGuest", formData);
}

function dataSync(idList) {
  //数据同步
  return request.post(constant.serverUrl + "/base/oldPersonInfo/dataSync", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function unbindWechat(id) {
  return request.post(constant.serverUrl + "/base/oldPersonInfo/unbindWechat", id, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function lifeRecordList(formData) {
  return request.post(constant.serverUrl + "/base/oldPersonInfo/lifeRecordList", formData);
}

function clearFaceImg(id) {
  return request.post(constant.serverUrl + "/base/oldPersonInfo/clearFaceImg", id, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function batchClearFaceImg(idList) {
  return request.post(constant.serverUrl + "/base/oldPersonInfo/batchClearFaceImg", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}


function enabledSync(formData) {
  //开启自动同步
  return request.post(constant.serverUrl + "/base/oldPersonInfo/enabledSync", formData);
}

function enabledSyncList(ids) {
  //开启自动同步
  return request.post(constant.serverUrl + "/base/oldPersonInfo/enabledSyncList", ids, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function bindWechat(userId) {
  var formData = new FormData();

  formData.append("expire_seconds", "3600");
  formData.append("action_name", "QR_STR_SCENE");
  formData.append("scene_str", process.env.VUE_APP_BIND_PERSON_WECHAT_CODE + "," + userId);

  return request.post(constant.serverUrl + "/wechat/qrcode/create", formData);
}

export default {
  pageList, create, edit, add, update, remove, batchRemove, exportXls,
  enabledFace, enabledCard, enabledApp, enabledGuest, dataSync, enabledFaceList,
  enabledWechatNotice, unbindWechat, lifeRecordList, clearFaceImg, batchClearFaceImg,
  enabledSync, enabledSyncList, bindWechat
}